import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { rowData } from './calculations';
import { TextField } from '@mui/material';

export interface RowDataProps {
  open: boolean;
  onClose: (value: rowData) => void;
}

export default function RowDataDialog(props: RowDataProps) {
  const { onClose, open } = props;
  const [lat, setLat] = React.useState("");
  const [long, setLong] = React.useState("");

  const handleClose = () => {
    onClose({id: -1, lat: 0, long: 0});
  };

  const handleAddItemClick = () => {
    onClose({id: 0, lat: Number(lat), long: Number(long)});
  };

  function handleLatChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLat(pData => event.target.value);
  }

  function handleLongChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLong(pData => event.target.value);
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add New Waypoint</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem>
            <TextField
                id="lat"
                label="Latitude in Degree Decimal"
                defaultValue={lat}
                onChange={handleLatChange}
            />
        </ListItem>
        <ListItem>
            <TextField
                id="long"
                label="Longitude in Degree Decimal"
                defaultValue={long}
                onChange={handleLongChange}
            />
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            onClick={() => handleAddItemClick()}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add Point" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}