import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { ListSubheader, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Buffermap from './Buffermap';
import { GridEventListener, GridCellEditCommitParams, GridCallbackDetails } from '@mui/x-data-grid/models';
import { MuiEvent, MuiBaseEvent } from '@mui/x-data-grid/models';
import { GeoJSON as gJson, Feature } from 'geojson';
import { rowData, strayDistance, getFlightPath, getBufferZone } from './calculations';
import RowDataDialog from './RowDataDialog';
import { Map } from 'leaflet';

const drawerWidth = 300;

const columns: GridColDef[] = [
    { field: 'lat', headerName:'Lat (DD)', width:140, editable: true },
    { field: 'long', headerName:'Long (DD)', width:140, editable: true }
]

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function MenuDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState<rowData[]>([]);
  const [altitude, setAltitude] = React.useState(300);
  const [speed, setSpeed] = React.useState(1.3);
  const [safetyDist, setSafetyDist] = React.useState(10);
  const [flightPath, setFlightPath] = React.useState<Feature>(getFlightPath(rows));
  const [bufferZone, setBufferZone] = React.useState<Feature>(
    getBufferZone(flightPath, strayDistance(altitude, speed) + safetyDist, 'meters')
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);

  
  // This silly useState for the key is required because of the way the 
  // React.leaflet GeoJSON works. It only refreshes if the key changes.
  const [flightPathKey, setFlightPathKey] = React.useState(1);
  const [bufferZoneKey, setBufferZoneKey] = React.useState(-1);
  const mapRef = React.useRef<Map>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = (value: rowData) => {
    setDialogOpen(false);
    if (value.id != -1) {
      setRows(pData => {
        var data = [...pData];
        const maxId = pData.reduce(
            (old, current) => Math.max(old, current.id), 0
        )
        data.push({id: maxId + 1, lat: value.lat, long: value.long})
        setFlightPath(pData => getFlightPath(data));
        setFlightPathKey(pData => pData + 1);
        setBufferZone(getBufferZone(getFlightPath(data), strayDistance(altitude, speed) + safetyDist, 'meters'));
        setBufferZoneKey(pData => pData - 1);
        if (mapRef) {
          mapRef.current?.flyTo([value.lat, value.long]);
        }
        return data;
    })
    }
  }

  function handleAltitudeChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isNaN(Number(event.target.value))) {
      setAltitude(pData => Number(event.target.value));
      setBufferZone(getBufferZone(flightPath, strayDistance(Number(event.target.value), speed) + safetyDist, 'meters'));
      setBufferZoneKey(pData => pData - 1);
    }
  }

  function handleSpeedChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isNaN(Number(event.target.value))) {
      setSpeed(pData => Number(event.target.value));
      setBufferZone(getBufferZone(flightPath, strayDistance(altitude, Number(event.target.value)) + safetyDist, 'meters'));
      setBufferZoneKey(pData => pData - 1);
    }
  }

  function handleSafetyDistChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isNaN(Number(event.target.value))) {
      setSafetyDist(pData => Number(event.target.value));
      setBufferZone(getBufferZone(flightPath, strayDistance(altitude, speed) + Number(event.target.value), 'meters'));
      setBufferZoneKey(pData => pData - 1);
    }
  }

  function handleCellCommit(
    params: GridCellEditCommitParams, 
    event: MuiEvent<MuiBaseEvent>, 
    details: GridCallbackDetails) {
        const oldRows = [...rows]
        const rowIdx = oldRows.findIndex((d) => d.id === params.id);
        oldRows[rowIdx] = {
            ...oldRows[rowIdx],
            [params.field]: params.value === "" ? null : Number(params.value)
        };
        setRows(pData => oldRows);
        setFlightPath(pData => {
          var data = getFlightPath(oldRows);
          return data;
        });
        setFlightPathKey(pData => pData + 1);
        setBufferZone(getBufferZone(getFlightPath(oldRows), strayDistance(altitude, speed) + safetyDist, 'meters'));
        setBufferZoneKey(pData => pData - 1);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            RavenBufferZone
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader 
                    component="div" 
                    id="nested-list-subheader"
                    sx={{ fontWeight: 'Bold' }}
                >
                    Flight Paramters
                </ListSubheader>
            }
        >
            <ListItem 
                key={'maxAltitude'} 
            >
                <TextField
                    required
                    id="maxAltitude"
                    label="Altitude in metres"
                    defaultValue={altitude}
                    onChange={handleAltitudeChange}
                />
            </ListItem>
            <ListItem key={'maxSpeed'}>
                <TextField
                    required
                    id="maxSpeed"
                    label="Speed in m/s"
                    defaultValue={speed}
                    onChange={handleSpeedChange}
                />
            </ListItem>
            <ListItem key={'safetyDist'}>
                <TextField
                    required
                    id="safetyDist"
                    label="Safety Distance in metres"
                    defaultValue={safetyDist}
                    onChange={handleSafetyDistChange}
                />
            </ListItem>
        </List>
        <Divider />
        <List
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{ fontWeight: 'Bold' }}>
                    Flight Path WGS84 Coordinates
                </ListSubheader>
            }
        >
            <ListItem key={'data'} disablePadding>
                <Box sx={{ width: 300}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        hideFooter
                        autoHeight
                        onCellEditCommit={handleCellCommit}
                        />
                </Box>
            </ListItem>
            <ListItem key={'addRow'} disablePadding>
                <ListItemButton
                    onClick={handleDialogOpen}
                >
                    <ListItemIcon>
                        <AddIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        Add Row
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Buffermap flightPath={flightPath} 
                   flightPathKey={flightPathKey}
                   bufferPath={bufferZone}
                   bufferPathKey={bufferZoneKey}
                   mapRef={mapRef}
                   />
      </Main>
      <RowDataDialog
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </Box>
  );
}