import { lineString, point, polygon, Units, buffer } from "@turf/turf";
import { Feature, GeoJSON } from "geojson";
import { GridRowEditStartReasons } from "@mui/x-data-grid";

export interface rowData {
    id: number,
    lat: number,
    long: number
}

export function strayDistance(hgt: number, 
                              vel: number, 
                              Egps: number = 0.75, 
                              Ev: number = 7.8): number {
    const g: number = 9.81
    const Timpact: number = Math.sqrt((2 * (hgt + Ev))/g)
    return vel * Timpact + Egps;
}

export function isRowsEq(pt1: rowData, pt2: rowData): boolean {
    return pt1.lat === pt2.lat && pt1.long === pt2.long;
}

export function getFlightPath(data: rowData[]): Feature {
    if (data.length === 0) {
        return point([103.82, 1.3]);
    } else if (data.length === 1) {
        return point([data[0].long, data[0].lat]);
    } else if (isRowsEq(data[0], data[data.length-1]) && data.length >= 4) {
        return polygon([data.map(x => [x.long, x.lat])], {name: 'flight path'});
    } else if (data.length !== 0) {
        return lineString(data.map(x => [x.long, x.lat]), {name: 'flight path'});
    } else {
        return polygon([[]]);
    }
}

export function getBufferZone(flightpath: Feature, bufferZone: number, units: Units): Feature {
    return buffer(flightpath, bufferZone, {units: units});
}