import { LatLngExpression, Map } from "leaflet";
import L from "leaflet";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer, GeoJSON, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { getFlightPath } from "./calculations";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41],
    iconAnchor: [12,41]
});

L.Marker.prototype.options.icon = DefaultIcon;

interface BuffermapProps {
    flightPath: GeoJSON.Feature,
    flightPathKey: number,
    bufferPath: GeoJSON.Feature,
    bufferPathKey: number,
    mapRef: React.Ref<Map>
}

export default function Buffermap(props: BuffermapProps) {
    const position: LatLngExpression = [1.35, 103.82];
    return (
        <MapContainer ref={props.mapRef} center={position} zoom={12} scrollWheelZoom={true}>
            <GeoJSON key={props.flightPathKey} data={props.flightPath} style={{color: "blue"}} />
            <GeoJSON key={props.bufferPathKey} data={props.bufferPath} style={{color: "red"}} />
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    )
}